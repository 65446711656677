import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import Box from "../../components/Box"
import BreadcrumbsAboutWrapper from "./breadcrumbsAbout.style"
import Container from "../../components/UI/Container"
import Text from "../../components/Text"

const BreadcrumbsAbout = ({
  currentPage,
  sectionBreadcrumb,
  breadcrumbItem,
}) => {
  return (
    <BreadcrumbsAboutWrapper>
      <Container width="1805px">
        <Box {...sectionBreadcrumb} className="box_wrapper">
          <Fade up delay={100}>
            <Link to="/">
              <Text content="Home" {...breadcrumbItem} />
            </Link>
            <Text content=">" {...breadcrumbItem} />
            <Link to="/about/">
              <Text content="About" {...breadcrumbItem} />
            </Link>
            <Text content=">" {...breadcrumbItem} />
            <Text content={`${currentPage}`} {...breadcrumbItem} />
          </Fade>
        </Box>
      </Container>
    </BreadcrumbsAboutWrapper>
  )
}

// BreadcrumbsTreatments style props
BreadcrumbsAbout.propTypes = {
  sectionBreadcrumb: PropTypes.object,
  currentPage: PropTypes.string,
  meetTheTeam: PropTypes.bool,
}

BreadcrumbsAbout.defaultProps = {
  sectionBreadcrumb: {
    flexBox: true,
  },
  // breadcrumbItem default style
  breadcrumbItem: {
    as: "span",
    fontSize: "14px",
    fontWeight: "300",
    mr: "6px",
    color: "#767474",
  },
}

export default BreadcrumbsAbout
