import "react-image-gallery/styles/css/image-gallery.css"

import BreadCrumbsAbout from "../containers/BreadcrumbsAbout"
import ChangeYourLife from "../containers/ChangeYourLife"
import Container from "../components/UI/Container"
import { ContentWrapper } from "../containers/elegant.style"
import Image1 from "../common/assets/image/elegant/imageGallery/1.jpg"
import Image10 from "../common/assets/image/elegant/imageGallery/10.jpg"
import Image11 from "../common/assets/image/elegant/imageGallery/11.jpg"
import Image12 from "../common/assets/image/elegant/imageGallery/12.jpg"
import Image13 from "../common/assets/image/elegant/imageGallery/13.jpg"
import Image14 from "../common/assets/image/elegant/imageGallery/14.jpg"
import Image15 from "../common/assets/image/elegant/imageGallery/15.jpg"
import Image16 from "../common/assets/image/elegant/imageGallery/16.jpg"
import Image17 from "../common/assets/image/elegant/imageGallery/17.jpg"
import Image2 from "../common/assets/image/elegant/imageGallery/2.jpg"
import Image3 from "../common/assets/image/elegant/imageGallery/3.jpg"
import Image4 from "../common/assets/image/elegant/imageGallery/4.jpg"
import Image5 from "../common/assets/image/elegant/imageGallery/5.jpg"
import Image6 from "../common/assets/image/elegant/imageGallery/6.jpg"
import Image7 from "../common/assets/image/elegant/imageGallery/7.jpg"
import Image8 from "../common/assets/image/elegant/imageGallery/8.jpg"
import Image9 from "../common/assets/image/elegant/imageGallery/9.jpg"
import ImageA from "../common/assets/image/elegant/imageGallery/a.jpeg"
import ImageB from "../common/assets/image/elegant/imageGallery/b.jpeg"
import ImageC from "../common/assets/image/elegant/imageGallery/c.jpeg"
import ImageD from "../common/assets/image/elegant/imageGallery/d.jpeg"
import ImageE from "../common/assets/image/elegant/imageGallery/e.jpeg"
import ImageF from "../common/assets/image/elegant/imageGallery/f.jpeg"
import ImageG from "../common/assets/image/elegant/imageGallery/g.jpeg"
import ImageGallery from "react-image-gallery"
import ImageH from "../common/assets/image/elegant/imageGallery/h.jpeg"
import ImageI from "../common/assets/image/elegant/imageGallery/i.jpeg"
import ImageJ from "../common/assets/image/elegant/imageGallery/j.jpeg"
import ImageK from "../common/assets/image/elegant/imageGallery/k.jpeg"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import React from "react"
import SEO from "../components/SEO"

const images = [
  {
    original: ImageA,
    thumbnail: ImageA,
    description: "",
  },
  {
    original: ImageB,
    thumbnail: ImageB,
    description: "",
  },
  {
    original: ImageC,
    thumbnail: ImageC,
    description: "",
  },
  {
    original: ImageD,
    thumbnail: ImageD,
    description: "",
  },
  {
    original: ImageE,
    thumbnail: ImageE,
    description: "",
  },
  {
    original: ImageF,
    thumbnail: ImageF,
    description: "",
  },
  {
    original: ImageG,
    thumbnail: ImageG,
    description: "",
  },
  {
    original: ImageH,
    thumbnail: ImageH,
    description: "",
  },
  {
    original: ImageI,
    thumbnail: ImageI,
    description: "",
  },
  {
    original: ImageJ,
    thumbnail: ImageJ,
    description: "",
  },
  {
    original: ImageK,
    thumbnail: ImageK,
    description: "",
  },
  {
    original: Image1,
    thumbnail: Image1,
    description: "",
  },
  {
    original: Image2,
    thumbnail: Image2,
    description: "",
  },
  {
    original: Image3,
    thumbnail: Image3,
    description: "",
  },
  {
    original: Image4,
    thumbnail: Image4,
    description: "",
  },
  {
    original: Image5,
    thumbnail: Image5,
    description: "",
  },
  {
    original: Image6,
    thumbnail: Image6,
    description: "",
  },
  {
    original: Image7,
    thumbnail: Image7,
    description: "",
  },
  {
    original: Image8,
    thumbnail: Image8,
    description: "",
  },
  {
    original: Image9,
    thumbnail: Image9,
    description: "",
  },
  {
    original: Image10,
    thumbnail: Image10,
    description: "",
  },
  {
    original: Image11,
    thumbnail: Image11,
    description: "",
  },
  {
    original: Image12,
    thumbnail: Image12,
    description: "",
  },
  {
    original: Image13,
    thumbnail: Image13,
    description: "",
  },
  {
    original: Image14,
    thumbnail: Image14,
    description: "",
  },
  {
    original: Image15,
    thumbnail: Image15,
    description: "",
  },
  {
    original: Image16,
    thumbnail: Image16,
    description: "",
  },
  {
    original: Image17,
    thumbnail: Image17,
    description: "",
  },
]

const Gallery = () => {
  return (
    <>
      <SEO
        title="Gallery"
        shortDesc="Elegant Beauty Clinic"
        description="Image Gallery"
      />
      <Layout>
        <ContentWrapper>
          <BreadCrumbsAbout currentPage="Gallery" />
          <PageTitle title="Image Gallery" subtitle="Elegant Beauty" />
          <Container width="1340px">
            <div style={{ marginTop: "50px" }}>
              <ImageGallery items={images} />
            </div>
          </Container>
          <ChangeYourLife
            title="Ready to change your life?"
            text="Begin your journey with us today. With Elegant Beauty clinics across the Bournemouth, Poole and Southampton area, we invite you to visit us for a free consultation at your convenience."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default Gallery
