import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import React from "react"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import PageTitleWrapper from "./pageTitle.style"
import Text from "../../components/Text"

const PageTitle = ({
  sectionHeader,
  sectionSubTitle,
  sectionTitleBlog,
  sectionCategory,
  sectionTitle,
  title,
  text,
  subtitle,
  addCategory,
}) => {
  const categoryColor =
    subtitle === "aesthetics"
      ? " #DECCB4"
      : subtitle === "health"
      ? "#A1D4D5"
      : "#FFB1AD"
  return (
    <PageTitleWrapper>
      <Container width="1740px">
        <Box {...sectionHeader}>
          <Fade up delay={100}>
            {addCategory ? (
              <Text
                {...sectionCategory}
                content={subtitle}
                className="post_category"
                style={{ backgroundColor: categoryColor }}
              />
            ) : (
              <>
                <Text
                  content={subtitle}
                  {...sectionSubTitle}
                  style={{ textTransform: "capitalize" }}
                />
              </>
            )}
            {addCategory ? (
              <Heading content={title} as="h1" {...sectionTitleBlog} />
            ) : (
              <>
                <Heading content={title} as="h1" {...sectionTitle} />
                {text !== undefined && <Text content={text} className="text" />}
              </>
            )}
          </Fade>
        </Box>
      </Container>
    </PageTitleWrapper>
  )
}

// PageTitle style props
PageTitle.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionTitleBlog: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  sectionCategory: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  addCategory: PropTypes.bool,
}

// PageTitle default style
PageTitle.defaultProps = {
  // section header default style
  sectionHeader: {
    mx: "auto",
    maxWidth: "980px",
  },
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "18px",
    letterSpacing: "0.72px",
    fontWeight: "300",
    mb: "12px",
    color: "#333333",
    fontFamily: "bariol-regular",
  },
  // category default style
  sectionCategory: {
    as: "span",
  },
  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["32px", "48px"],
    fontWeight: "100",
    letterSpacing: "0.96px",
    lineHeight: ["38px", "56px"],
    mb: "0",

    color: "#333333",
  },
  sectionTitleBlog: {
    textAlign: "center",
    fontSize: ["32px", "48px"],
    fontWeight: "100",
    letterSpacing: "0.96px",
    lineHeight: ["38px", "56px"],
    mb: "0",
    mt: ["16px", "32px"],
    color: "#333333",
  },
}

export default PageTitle
