import styled from "styled-components"

const BreadcrumbsAboutWrapper = styled.section`
  padding: 16px 0 0 0;
  overflow: hidden;

  .box_wrapper {
    div:last-child {
      text-decoration: underline;
      color: #767474;
    }
  }

  span {
    text-transform: capitalize;
  }
`

export default BreadcrumbsAboutWrapper
