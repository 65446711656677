import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const PageTitleWrapper = styled.section`
  padding: 120px 0 0 0;
  overflow: hidden;

  @media (max-width: 990px) {
    padding: 60px 0 0 0;
  }
  @media (max-width: 767px) {
    padding: 40px 0 0 0;
  }

  .post_category {
    font-size: 12px;
    line-height: 28px;
    color: ${themeGet("colors.textColor", "#333333")};
    padding: 4px 16px;
    border-radius: 16px;
    text-transform: capitalize;
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  .text {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .react-reveal {
    text-align: center;
  }
`

export default PageTitleWrapper
